import React from "react"
import { graphql } from "gatsby"
import Container from "react-bootstrap/Container"
import sanitizeHtml from "sanitize-html"

/* Images */
import NavBarLogo from "../images/logo.svg"

/* Components */
import Wrapper from "../components/wrapper/wrapper"
import SEO from "../components/seo/seo"
import NavBar from "../components/navbar/navbar"
import Main from "../components/main/main"
import PostContent from "../components/post-content/post-content"
import Footer from "../components/footer/footer"

export default function Document({ location, data }) {
  const DocumentTitle = data.markdownRemark.frontmatter.title
  const DocumentDescription = data.markdownRemark.frontmatter.description
  const DocumentImg = data.markdownRemark.frontmatter.postImg

  const DocumentContent = { __html: sanitizeHtml(data.markdownRemark.html) }

  return (
    <Wrapper>
      <SEO title={DocumentTitle} description={DocumentDescription} />
      <NavBar classVariant="navBarDefault" logo={NavBarLogo} />
      <Main>
        <Container>
          <PostContent className="col-md-12">
            <div dangerouslySetInnerHTML={DocumentContent}></div>
            {DocumentImg && (
              <img
                src={DocumentImg}
                alt={DocumentTitle}
                className="my-5 mx-auto d-block img-fluid"
              />
            )}
          </PostContent>
        </Container>
      </Main>
      <Footer />
    </Wrapper>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        title
        description
        postImg
      }
    }
  }
`
